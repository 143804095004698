import React, { useState, useEffect } from "react";
import { CustomInput } from "../../components/CustomInput";
import { CustomButton } from "../../components/CustomButton";
import ReactCodeInput from "react-code-input";
import { EmailValidate, PhoneValidate } from "../../helpers/validate";
import { useAssets, useMobile } from "../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { postWithoutToket, putData } from "../../helpers/endpoints";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { MuiOtpInput } from "mui-one-time-password-input";

import "./style.scss";

const ForgetPassword = () => {
  const isMobile = useMobile();
  const { getFile } = useAssets();
  const { step, s, email } = useParams();
  const [page, setPage] = useState(step ? parseInt(step) : 1);
  const [input, setinput] = useState(email ? email : "");
  const [secret, setSecret] = useState(s ? s : 0);
  const [isLoading, setIsLoading] = useState(false);
  const navigator = useNavigate();

  const handleSubmit = async () => {
    setIsLoading(true);
    if (EmailValidate(input)) {
      var body = { email: input, password: "", forceOTP: true };
      var res = await postWithoutToket("auth/login", JSON.stringify(body));
      if (res.status != 201) {
        Swal.fire({
          title: "",
          text: res,
          icon: "error",
          confirmButtonText: "Ok",
        });
      } else {
        if (!res.data.data.newUser) {
          setPage(2);
          setSecret(res.data.data.secret);
        } else {
          Swal.fire({
            title: "You do not have an account!",
            text: "Please register first",
            icon: "error",
            confirmButtonText: "Ok",
          });
          navigator("/sign-in");
        }
      }
    } else {
      Swal.fire({
        title: "Invalid Email",
        text: "Please enter a valid email",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
    setIsLoading(false);
  };

  return (
    <div className="forget col-12">
      <div className="forget-header d-flex flex-row align-items-center gap-2 pt-2 px-4 ">
        <img
          src={getFile("BackIcon")}
          alt="back"
          onClick={() => navigator("/sign-in")}
        />
        <a href="https://allbrainsclinic.com/" target={"_self"}>
          {" "}
          <img src={getFile("Logo")} alt="logo" />{" "}
        </a>
      </div>
      {page === 1 && (
        <div className="forget-box d-flex flex-column  align-items-center gap-3 ">
          <h2>Forgot Password</h2>
          <h3>Please enter your Email address </h3>
          <CustomInput
            fullWidth
            placeholder="Enter your Email"
            additionalClassNames={isMobile ? "signupinput my-4" : "my-4 "}
            value={input}
            onChange={(e) => {
              setinput(e.toLowerCase());
            }}
          />
          <CustomButton
            title={"Submit"}
            additionalClassNames="px-5"
            onClick={handleSubmit}
            loading={isLoading}
          />
        </div>
      )}
      {page === 2 && (
        <SubmitCode
          email={input}
          secret={secret}
          sendCode={handleSubmit}
          setPage={setPage}
        />
      )}
      {page === 3 && <ChangePass email={input} />}
    </div>
  );
};

const SubmitCode = ({ email, secret, sendCode, setPage }) => {
  const isMobile = useMobile();
  const [isPinCodeValid, setIsPinCodeValid] = useState(true);
  const [pinCode, setPinCode] = useState("");
  const [counter, setCounter] = useState(120);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const props = {
    inputStyle: {
      outline: "none",
      MozAppearance: "textfield",
      margin: "3%",
      width: "10%",
      fontSize: "18px",
      textAlign: "center",
      borderBottom: "2px solid #212529",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
    },
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    var data = { code: parseInt(pinCode), secret: secret, identifier: email };

    var res = await postWithoutToket("auth/verify", JSON.stringify(data));
    if (res.status != 201) {
      Swal.fire({
        title: "",
        text: res,
        icon: "error",
        confirmButtonText: "Ok",
      });
    } else {
      Cookies.set("access_token", res.data.data.token);
      setPage(3);
    }
    setIsLoading(false);
  };

  const handlePinChange = (pinCode) => {
    if (pinCode === "") setIsPinCodeValid(true);
    setPinCode(pinCode);
  };

  const handleResend = () => {
    if (counter <= 0) {
      sendCode();
      setCounter(90);
    }
  };

  return (
    <div
      className={
        isMobile
          ? "forget-box d-flex flex-column align-items-center gap-3"
          : "sign-up-box d-flex flex-column justify-content-center align-items-center gap-3 px-5 "
      }
    >
      <h2>Forget Password</h2>
      <h3 className={isMobile ? "px-2" : ""}>
        Please enter the code we sent to {email}
      </h3>
      {/* <ReactCodeInput
        type="number"
        fields={6}
        isValid={isPinCodeValid}
        onChange={handlePinChange}
        value={pinCode}
        {...props}
      /> */}
      <MuiOtpInput value={pinCode} onChange={handlePinChange} length={6} />
      <div className="d-flex flex-row gap-5">
        <h3
          className={counter > 0 ? "disable pr-2" : "enable pr-2"}
          onClick={handleResend}
        >
          Resend Code
        </h3>
        <h3 style={{ color: "#a1813a" }}>
          {Math.floor(counter / 60)}:{counter % 60}
        </h3>
      </div>
      <CustomButton
        title={"Submit"}
        additionalClassNames={isMobile ? "px-5 mt-4" : "px-5"}
        onClick={handleSubmit}
        loading={isLoading}
      />
    </div>
  );
};
const ChangePass = ({ email }) => {
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigator = useNavigate();

  const handleSubmit = async () => {
    setIsLoading(true);
    if (confPassword !== password) {
      Swal.fire({
        title: "",
        text: "The password and confirmation password do not match.",
        icon: "error",
        confirmButtonText: "Ok",
      });
      setIsLoading(false);
      return;
    }
    if (password.length < 8) {
      Swal.fire({
        title: "",
        text: "Password must be at least 8 characters",
        icon: "error",
        confirmButtonText: "Ok",
      });
      setIsLoading(false);
      return;
    }

    var data = {
      email,
      password: password,
      password_confirmation: confPassword,
    };

    var res = await putData("user", JSON.stringify(data));
    if (res.status != 200) {
      Swal.fire({
        title: "",
        text: res,
        icon: "error",
        confirmButtonText: "Ok",
      });
    } else {
      Swal.fire({
        title: "",
        text: "Your password has been successfully changed",
        icon: "success",
        confirmButtonText: "Ok",
      });
      navigator("/sign-in");
    }
    setIsLoading(false);
  };
  const isMobile = useMobile();
  return (
    <div
      className={
        isMobile
          ? "forget-box d-flex flex-column align-items-center gap-3 "
          : "forget-box d-flex flex-column justify-content-center align-items-center gap-3 "
      }
      style={{ height: "65vh" }}
    >
      <h2>Forgot Password</h2>
      <h3>Please enter a new Password</h3>
      <div
        style={{ paddingRight: "1rem", paddingLeft: "0.5rem" }}
        className="col-12 d-flex flex-column gap-3"
      >
        <CustomInput
          type="password"
          fullWidth
          placeholder="Enter your Password"
          value={password}
          additionalClassNames={isMobile ? "" : ""}
          onChange={(e) => {
            setPassword(e);
          }}
          label={"New Password"}
          required
        />
        <CustomInput
          type="password"
          fullWidth
          placeholder="Enter your Password"
          additionalClassNames={isMobile ? "" : ""}
          value={confPassword}
          onChange={(e) => {
            setConfPassword(e);
          }}
          label="Confirm New Password"
          required
        />
      </div>
      <CustomButton
        title={"Submit"}
        additionalClassNames={isMobile ? "px-5 my-4" : "px-5"}
        onClick={handleSubmit}
        loading={isLoading}
      />
    </div>
  );
};

export default ForgetPassword;
