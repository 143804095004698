import { useEffect, useState } from "react";
import "./styles.scss";
import { useLocation, useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ServiceCard from "./ServiceCard";
import ServicePage from "./ServicePage";
import { useAssets } from "../../../../hooks";
import { fetchData } from "../../../../helpers/endpoints";
import FadeLoader from "react-spinners/FadeLoader";
import { useMobile } from "../../../../hooks";
import { Steps, Hints } from "intro.js-react";
import Cookies from "js-cookie";
import { useConfig } from "../../../../hooks/useConfig";

import "intro.js/introjs.css";
import Swal from "sweetalert2";

const Services = ({ patientId, mrn, email, name, serviceId, userId }) => {
  const navigator = useNavigate();
  const pathname = useLocation().pathname;
  const [active, setActive] = useState(1);
  const [services, setServices] = useState([]);
  const [similar, setsimilar] = useState([]);
  const [userServices, setUserServices] = useState([]);
  const [userComplateServices, setComplateUserServices] = useState([]);
  const [current, setCurrent] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPurchase, setIsPurchase] = useState(false);
  const [coverUrl, setCoverUrl] = useState("");
  const isMobile = useMobile();
  const { getUserConfig } = useConfig();

  const [stepsEnabled, setStepsEnabled] = useState(false);
  const initialStep = 0;
  const steps = [
    {
      element: ".to-begin",
      intro: `By clicking "To Begin," you can go to the services page and gain more information.`,
      position: "top",
    },
  ];

  const fetchServices = async () => {
    setLoading(true);
    var res = await fetchData(`patient/${patientId}/service`);
    if (res.status == 200) {
      setServices(
        res.data.data[1].services.filter((s) => {
          return !s.deletedAt && s.publishedAt;
        })
      );
      setsimilar(
        res.data.data[0].services.filter((s) => {
          return !s.deletedAt && s.publishedAt;
        })
      );
    }
    var res2 = await fetchData(`patient/${patientId}/service/purchase/index`);
    if (res2.status == 200) {
      var temp1 = [];
      var temp2 = [];
      res2.data.data.map((s) => {
        if (s.status == "in_progress" || s.status == "done_by_patient") {
          temp2 = [...temp2, s];
        } else if (s.status == "done_by_clinic") {
          temp1 = [...temp1, s];
        }
      });
      setComplateUserServices(temp1);
      setUserServices(temp2);
      if (res2.data.data.length == 0) {
        setActive(0);
      }
    }

    getUserConfig();
    var res3 = JSON.parse(localStorage.getItem("config"));
    if (res3.status == 200) {
      setCoverUrl(res3.data.data.storage.bucket.url);
    }
    setLoading(false);
  };
  const fetchService = async () => {
    setLoading(true);
    var domain = pathname.includes("purchase")
      ? `patient/${patientId}/service/purchase/${serviceId}`
      : `patient/${patientId}/service/${serviceId}`;
    var res = await fetchData(domain);
    if (res.status == 200) {
      if (pathname.includes("purchase"))
        setCurrent({
          id: serviceId,
          description: res.data.data.description,
          service: res.data.data.service,
        });
      else {
        setCurrent(res.data.data);
      }
      setIsPurchase(pathname.includes("purchase"));
      if (!coverUrl) {
        getUserConfig();
        var res3 = JSON.parse(localStorage.getItem("config"));
        if (res3.status == 200) {
          setCoverUrl(res3.data.data.storage.bucket.url);
        }
      }
      setIsOpen(true);
    } else {
      Swal.fire({ text: res, icon: "error" });
      navigator(pathname.replace("/" + serviceId, ""));
      await fetchServices();
      setIsOpen(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (serviceId) {
      fetchService();
    } else {
      fetchServices();
      setIsOpen(false);
    }
    var bool = Cookies.get("index-tooltip");
    if (bool == "false" || !bool) {
      setStepsEnabled(true);
    }
  }, [patientId]);

  return (
    <div
      dir="ltr"
      className={`d-flex flex-column w-100 ${
        isMobile ? "py-3 gap-1" : "py-5 gap-4"
      } `}
    >
      <Steps
        enabled={stepsEnabled && !serviceId}
        steps={steps}
        initialStep={initialStep}
        onExit={() => {
          setStepsEnabled(false);
          Cookies.set("index-tooltip", true, { expires: 365 });
        }}
      />
      {isOpen ? (
        <ServicePage
          current={current}
          setIsOpen={setIsOpen}
          patientId={patientId}
          isPurchase={isPurchase}
          name={name}
          mrn={mrn}
          email={email}
          fetchServices={fetchServices}
          setIsPurchase={setIsPurchase}
          userId={userId}
          coverUrl={coverUrl}
        />
      ) : serviceId ? (
        <FadeLoader color={"#18324e"} />
      ) : (
        <>
          {" "}
          <div
            className={
              isMobile
                ? "my-first-step services1 d-flex flex-row w-50"
                : "my-first-step services1 d-flex flex-row w-50 gap-4 "
            }
          >
            <button
              className={
                active == 0
                  ? `active ${isMobile && "col-6"}`
                  : `${isMobile && "col-6"}`
              }
              onClick={() => {
                setActive(0);
              }}
            >
              All
            </button>
            <button
              className={
                active == 1
                  ? `active ${isMobile && "col-6"}`
                  : `${isMobile && "col-6"}`
              }
              onClick={() => {
                setActive(1);
              }}
            >
              In Progress
            </button>
            <button
              className={
                active == 2
                  ? `active ${isMobile && "col-6"}`
                  : `${isMobile && "col-6"}`
              }
              onClick={() => {
                setActive(2);
              }}
            >
              Completed
            </button>
            <button
              onClick={() => {
                setActive(0);
                setStepsEnabled(true);
              }}
            >
              <svg
                // class="svg-icon"
                style={{ width: "1.5em", height: "1.5em" }}
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M514.688 707.2m-48.96 0a48.96 48.96 0 1 0 97.92 0 48.96 48.96 0 1 0-97.92 0Z" />
                <path d="M398.08 309.952c-32.32 35.136-67.328 138.24 7.936 140.032 41.984 1.024 41.6-25.088 43.712-31.296s6.336-35.648 19.008-47.936 18.88-18.368 38.592-18.368c19.328 0 34.624 5.12 46.144 15.168 11.584 10.112 17.28 22.336 17.28 36.672a54.848 54.848 0 0 1-7.04 28.416c-4.736 7.936-18.304 22.464-40.896 43.584-23.424 22.016-40.64 45.568-51.008 70.528-22.656 54.656 61.056 71.36 77.888 47.104 16.512-23.872 6.912-28.48 46.528-67.968 19.776-18.688 32.192-32.192 37.376-40.32 8.96-13.44 15.296-26.688 19.392-39.616a139.072 139.072 0 0 0-13.056-110.464c-38.912-68.48-160.96-113.472-241.856-25.536z" />
                <path d="M508.544 66.88A448 448 0 0 0 60.992 514.432a448 448 0 0 0 447.552 447.552 448 448 0 0 0 447.552-447.552 448 448 0 0 0-447.552-447.552z m0 831.104a384 384 0 0 1-383.552-383.552 384 384 0 0 1 383.552-383.552 384 384 0 0 1 383.552 383.552 384 384 0 0 1-383.552 383.552z" />
              </svg>
            </button>
          </div>
          <div>
            {active == 0 ? (
              <Home
                services={services}
                similar={similar}
                setCurrent={setCurrent}
                setIsOpen={setIsOpen}
                loading={loading}
                setIsPurchase={setIsPurchase}
                coverUrl={coverUrl}
              />
            ) : active == 1 ? (
              <InProgress
                services={userServices}
                setCurrent={setCurrent}
                setIsOpen={setIsOpen}
                loading={loading}
                setIsPurchase={setIsPurchase}
                allService={services}
                coverUrl={coverUrl}
              />
            ) : (
              <Completed
                services={userComplateServices}
                setCurrent={setCurrent}
                setIsOpen={setIsOpen}
                loading={loading}
                setIsPurchase={setIsPurchase}
                allService={services}
                coverUrl={coverUrl}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};
const responsive = {
  desktop2: {
    breakpoint: { max: 4000, min: 2000 },
    items: 4,
    slidesToSlide: 2, // optional, default to 1.
  },
  desktop: {
    breakpoint: { max: 2000, min: 991 },
    items: 3,
    slidesToSlide: 2, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 991, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
const Home = ({
  services,
  similar,
  setCurrent,
  setIsOpen,
  loading,
  setIsPurchase,
  coverUrl,
}) => {
  return (
    <div>
      {similar.length > 0 && (
        <div className="d-flex flex-column gap-lg-4 gap-2 py-4">
          <h2 className="title">It May Work For You</h2>
          {loading ? (
            <FadeLoader color={"#18324e"} />
          ) : (
            <div className="more-info to-begin">
              {" "}
              <Carousel
                swipeable={true}
                showDots={true}
                responsive={responsive}
                ssr={true}
                infinite={true}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                dotListClass="custom-dot-list-style"
                itemClass="px-3"
                renderDotsOutside={true}
              >
                {similar.map((s, i) => {
                  return (
                    <ServiceCard
                      service={s}
                      buttonType={"To Begin"}
                      setCurrent={setCurrent}
                      setIsOpen={setIsOpen}
                      isPurchase={false}
                      setIsPurchase={setIsPurchase}
                      url={coverUrl}
                    />
                  );
                })}
              </Carousel>
            </div>
          )}
        </div>
      )}
      <div className="d-flex flex-column gap-lg-4 gap-1 py-4">
        <h2 className="title">All Services</h2>
        {loading ? (
          <FadeLoader color={"#18324e"} />
        ) : (
          <Carousel
            swipeable={true}
            rewindWithAnimation={true}
            showDots={true}
            responsive={responsive}
            ssr={true}
            infinite={true}
            autoPlay={false}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            dotListClass="custom-dot-list-style"
            itemClass="px-3"
            renderDotsOutside={true}
          >
            {services.map((s, i) => {
              return (
                <ServiceCard
                  service={s}
                  buttonType={"To Begin"}
                  setCurrent={setCurrent}
                  setIsOpen={setIsOpen}
                  isPurchase={false}
                  setIsPurchase={setIsPurchase}
                  url={coverUrl}
                />
              );
            })}
          </Carousel>
        )}
      </div>
    </div>
  );
};
const InProgress = ({
  services,
  setCurrent,
  setIsOpen,
  loading,
  setIsPurchase,
  allService,
  coverUrl,
}) => {
  return (
    <div className="d-flex flex-column gap-lg-4 gap-1 py-4">
      <h2 className="title">In Progress Services</h2>
      {loading ? (
        <FadeLoader color={"#18324e"} />
      ) : services.length > 0 ? (
        <Carousel
          swipeable={true}
          rewindWithAnimation={true}
          renderDotsOutside={true}
          showDots={true}
          responsive={responsive}
          ssr={true}
          infinite={true}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          dotListClass="custom-dot-list-style"
          itemClass="px-3"
        >
          {services.map((s) => {
            return (
              <ServiceCard
                service={s}
                buttonType={"Continue"}
                setCurrent={setCurrent}
                setIsOpen={setIsOpen}
                isPurchase={true}
                setIsPurchase={setIsPurchase}
                allService={allService}
                url={coverUrl}
              />
            );
          })}
        </Carousel>
      ) : (
        <NoData />
      )}
    </div>
  );
};
const Completed = ({
  services,
  setCurrent,
  setIsOpen,
  loading,
  setIsPurchase,
  allService,
  coverUrl,
}) => {
  return (
    <div className="d-flex flex-column gap-lg-4 gap-1 py-4">
      <h2 className="title">Completed Services</h2>
      {loading ? (
        <FadeLoader color={"#18324e"} />
      ) : services.length > 0 ? (
        <Carousel
          swipeable={true}
          renderDotsOutside={true}
          rewindWithAnimation={true}
          showDots={true}
          responsive={responsive}
          ssr={true}
          infinite={true}
          // autoPlay={true}
          // autoPlaySpeed={3000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          dotListClass="custom-dot-list-style"
          itemClass="px-3"
        >
          {services.map((s) => {
            return (
              <ServiceCard
                service={s}
                buttonType={"Readd"}
                setCurrent={setCurrent}
                setIsOpen={setIsOpen}
                isPurchase={true}
                setIsPurchase={setIsPurchase}
                allService={allService}
                url={coverUrl}
              />
            );
          })}
        </Carousel>
      ) : (
        <NoData isComplate={true} />
      )}
    </div>
  );
};
const NoData = ({ isComplate = false }) => {
  const { getFile } = useAssets();
  return (
    <div
      className="d-flex flex-row"
      style={{
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={getFile("noData")} width={150} />
      <p
        style={{
          fontWeight: "600",
          fontSize: "16px",
          textAlign: "center",
          lineHeight: "22px",
        }}
      >
        {!isComplate
          ? " There are no services to display. Please add a service from the All section first."
          : " There are no services to display."}
      </p>
    </div>
  );
};
export default Services;
